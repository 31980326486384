// Node Modules
import css from "styled-jsx/css"
import styled from "@emotion/styled";

// Theme
import theme from "@/theme";

export const StylesHoursAndInformation = () => {
  return css.resolve`

    .access-hours-container{
    display: flex;
    justify-content: center;
    width: 100%;
    }
    .title-container {
      display: flex;
      justify-content: center;
      padding: 5rem 0 0 0;
      width: 100%;
    }

    .title-text{
      align-items: center;
      align-content: center;
      color: ${theme.borderColors.black};
      display: flex;
      justify-content: center;
    }

    svg.title-icon,
    .features-icon.svg-inline--fa.fa-w-16{
      font-size: 6.3rem;
      margin-right: 2rem;
    }

    .content-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 5% 0 5% 5%;
    }
    .divider-line{
      border-bottom: .03rem solid ${theme.borderColors.lightGray};
      width: 90%;
      margin: auto;
    }

    .features-container{
      padding: 5% 0 5% 5%;
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 90%;
      font-family: ${theme.fontFamilies.arial};
      font-size: 3.2rem;
      line-height: 5rem;
    }

    li.feature-item{
      margin-left: 4rem;
    }

    ul.feature-ul{
      columns: 2;
      list-style: disc;
    }

    .hours-container{
      padding: 7%;
      width: 75%;
    }

    .access-info{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      width: 100%;
    }

    .container{
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 90%;
    }

    .features-list{
      column-gap: 10rem;
      display: flex;
      font-family: ${theme.fontFamilies.arial};
      font-size: 3.2rem;
      justify-content: flex-end;
      line-height: 5rem;
      text-transform: capitalize;
      padding: 3% 0% 0% 20%;
    }

    .title-span{
      margin: 0rem 2rem 0rem 0rem;
    }

    .phone-number-box{
      align-items: center;
      display: flex;
      flex-direction: column;
      line-height: 1.6;
    }

    .phone-number-box-row{
      display: grid; 
      font-size: 4rem;
      grid-template-columns: repeat(2, 1fr); 
      grid-column-gap: 10px;
    }

    .phone-number-label{
      font-size: 3.2rem;
      font-weight: 600;
      min-width: 350px;
    }

    .phone-number-text{
      font-size: 3rem;
      display:flex;
    }  
  `
};

export const StyledAccessHoursContent = styled.div`
  & ul{
    list-style: disc;
    padding: 1rem 5rem;
    & li {
      padding: 0.5rem 0rem;
    }
  }
`

export const StyledOfficeHoursContent = styled.div`
  & p{
    padding: 1rem 1.5rem;
  }
`
