// Components
import PageTitle from "@/gc/page-title/PageTitle";
import { QRCodeCanvas } from "qrcode.react";

// Context
import {
  featureNames,
  useFeatureToggleContext
} from "@/context/FeaturesToggleContext";

// Scripts
import {
  accessibilitySizeOptions,
  getAccessibilitySize
} from "@/scripts/accessibilityHelper";

// Service
import { getSalesContactInformationItem } from "@/components/services/contactInformationService";

// Types
import INoInventoryScreenProps from "@/interfaces/splash-screen/sub-components/INoInventoryScreenProps";

// Utils
import { getNoInventoryURL } from "@/scripts/kioskUtil";
import { useDeviceInformationContext } from "@/context/device-information/DeviceInformationContext";

const NoInventoryScreen = ({
  content,
  address,
}: INoInventoryScreenProps) => {
  const url = getNoInventoryURL(address);

  const {
    deviceInformation,
  } = useDeviceInformationContext();

  const qrCodeImageSettings = {
    src: url,
    height: 400,
    width: 400,
    excavate: false,
  };

  const {
    callUsText,
    header,
    otherOptionsHeader,
    otherOptionsSubHeader,
    subHeader,
  } = content.noInventoryScreen.fields;
  
  const {
    featureToggleList,
  } = useFeatureToggleContext();
  
  const {
    contactInformationList,
  } = content?.hoursAndInformation?.fields;

  const featureToggle = featureToggleList.find(item => item.featureName == featureNames.flex);
  const useTwilioFlex: boolean = !!featureToggle && featureToggle.isEnabled;
  const contactInformationItem = getSalesContactInformationItem(contactInformationList, deviceInformation, useTwilioFlex);
  const accessibilitySize = getAccessibilitySize(document);

  return (
    <>
      <main>
        <PageTitle
          containerPadding="5.7% 0% 7% 0%"
          subTitle={subHeader}
          subTitleTestId="no-inventory-sub-inventory"
          title={header}
          titleTestId="no-inventory-header"
        />
        <div
          className="qrcode-container"
          data-testid="qrcode-container"
        >
          <QRCodeCanvas
            data-testid="qrcode"
            imageSettings={qrCodeImageSettings}
            size={400}
            value={url}
          />
        </div>
        <hr />
        <div className={`other-options-section-${accessibilitySize.size}`}>
          <h2
            className={`other-options-header-${accessibilitySize.size}`}
            data-testid="other-options-header"
          >
            {otherOptionsHeader}
          </h2>
          <p
            className={`other-options-subheader-${accessibilitySize.size}`}
            data-testid="other-options-subheader-1"
          >
            {otherOptionsSubHeader}
          </p>
          <p
            className={`other-options-subheader-${accessibilitySize.size}`}
            data-testid="other-options-subheader-2"
          >
            <span>{callUsText}</span><br /><span>{contactInformationItem.phoneNumber}</span>
          </p>
        </div>
      </main>
      <style jsx>{`
        .other-options-header-${accessibilitySizeOptions.large.size}{
          margin-top: 1rem;
          margin-bottom: 2rem;
        }
        .other-options-header-${accessibilitySizeOptions.medium.size}{
          margin-top: 2rem;
          margin-bottom: 4rem;
        }
        .other-options-header-${accessibilitySizeOptions.small.size}{
          margin-top: 4rem;
          margin-bottom: 7rem;
        }
        hr {
          width: 62%;
        }
        main {
          text-align: -webkit-center;
        }
        .other-options-subheader-${accessibilitySizeOptions.large.size} {
          font-size: 3.8rem;
          margin: 2rem 0;
        }
        .other-options-subheader-${accessibilitySizeOptions.medium.size},
        .other-options-subheader-${accessibilitySizeOptions.small.size}
         {
          font-size: 3.8rem;
          margin: 4rem 0;
        }
        .other-options-section-${accessibilitySizeOptions.large.size} {
          padding-top: 1rem;
          width: 77%;
        }
        .other-options-section-${accessibilitySizeOptions.medium.size},
        .other-options-section-${accessibilitySizeOptions.small.size}
         {
          padding-top: 5rem;
          width: 77%;
        }
        .qrcode-container {
          height: 33%;
          margin-bottom: 10rem;
          width: 100%;
        }
        `}
      </style>
    </>
  );
};

export default NoInventoryScreen;
