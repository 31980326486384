// Node Modules
import Link from "next/link";

// Components
import FontAwesomeIcon from "@/gc/icon/FontAwesomeIcon";

// Services
import dataLayerService from "@/services/dataLayerService";

// Theme
import theme from "@/theme";

// Types
import ISecondaryButtonProps from "@/interfaces/splash-screen/sub-components/ISecondaryButtonProps";

const SecondaryButton = ({
  dataLayerEventName,
  href,
  icon,
  id,
  label,
  onClick,
  testId,
  type,
}: ISecondaryButtonProps) => {
  const buttonContent = (
    <>
      <div className="option-content">
        <span className="label-description-container">
          <p className="option-label">{label}</p>
        </span>
        {icon && (
          <div
            className="icon-container"
            data-testid={`icon-container-${testId}`}
          >
            <FontAwesomeIcon
              color={icon.color}
              icon={icon.iconDefinition}
              size={icon.size}
            />
          </div>
        )}
      </div>

      <style jsx>
        {`

          .icon-container { 
            display: flex;
            align-items: center;
            justify-content: center;
            width: 25%;
          }

          .label-description-container {
            text-align: left;
            width: 100%;
          }
          
          .option-content {
            display: flex;
            padding: 3rem 6.5rem 3rem 4.5rem;
            width: 100%;
          }

          .option-label {
            font-family: ${theme.fontFamilies.ramaGothicSemiBold};
            font-size: 4.5rem;
            text-transform: uppercase;
          }
        `}
      </style>
    </>
  );

  return (
    <>
      {href
        ? (
          <Link
            legacyBehavior
            href={href}
          >
            <button
              data-testid={testId}
              id={id}
              type={type}
            >
              {buttonContent}
            </button>
          </Link>
          )
        : (
          <button
            data-testid={testId}
            id={id}
            type={type}
            onClick={() => dataLayerService.onClickWithDataLayerEvent(onClick, dataLayerEventName)}
          >
            {buttonContent}
          </button>
          )}
      <style jsx>
        {`          
          button {
            display: flex;
            align-items: center;
            justify-content: space-around;
            background-color: ${theme.brandColors.white};
            border: none;
            color: ${theme.fontColors.primaryGray};
            font-size: 4.5rem;
            line-height: 5.4rem;
            height: 8rem;
            width: 77%;
          }
      `}
      </style>
    </>
  );
};

export default SecondaryButton;
