// Node Modules
import css from "styled-jsx/css"

// theme
import theme from "@/theme";

export const StylesPageTitle = (containerPadding?:string, width?:string) => {
  return css.resolve`

    .bold{
        font-weight: bold;
        }

    .icon-container {
        font-size: 3rem;
        padding-top: 20%;
    }
        
    .page-title {
        width: 90%;
        text-align: center;
    }
    
    .page-text{
        font-size: 3.8rem;
        margin-top: 2%;
        text-align: center;
        width: 95%;
    }

    .page-title-container {
        align-items: center;
        color: ${theme.textColors.black};
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: ${containerPadding ?? "15% 0 7% 0"};
        width: width? ${width} : "auto";
    
    }
        `
}

