// Scripts
import dataLayerTypes from "@/scripts/constant-types/google-analytics/dataLayerTypes";
import { getCurrentTime } from "@/scripts/dateHelper";

// Services
import dataLayerService from "@/services/dataLayerService";

const isLastSessionOpen = (): boolean => {
  const dataLayer = dataLayerService.getDataLayer();
  const sessions = dataLayer?.filter(item => item.event?.includes("session_"));
  const lastSession = sessions.pop();
  return !!lastSession && lastSession.event === dataLayerTypes.events.sessionStarted;
};

const isSessionEventAlreadyRegistered = (eventName: string, userId: string): boolean => {
  const dataLayer = dataLayerService.getDataLayer();

  const session = dataLayer.find(item => item.event === eventName && item.user_id === userId);
  return !!session;
};

const createSessionEvent = (eventName: string, ozStoreId: string, userId: string) => {
  const event = {
    event: eventName,
    store_id: ozStoreId,
    time: getCurrentTime(),
    user_id: userId,
  };

  return event;
};

const pushDataLayerEvent = (eventName: string, ozStoreId: string, userId: string) => {
  const isEventAlreadyRegistered = isSessionEventAlreadyRegistered(eventName, userId);
  if (!isEventAlreadyRegistered) {
    dataLayerService.pushEvent(createSessionEvent(eventName, ozStoreId, userId));
  }
};

const startSession = (ozStoreId: string, userId: string): void => {
  if (!isLastSessionOpen()) {
    const eventName: string = dataLayerTypes.events.sessionStarted;
    pushDataLayerEvent(eventName, ozStoreId, userId);
  }
};

const endSession = (ozStoreId: string, userId: string): void => {
  if (isLastSessionOpen()) {
    const eventName: string = dataLayerTypes.events.sessionEnded;
    pushDataLayerEvent(eventName, ozStoreId, userId);
  }
};

export default {
  endSession,
  isLastSessionOpen,
  startSession,
};
