// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-unused-vars */
// Components
import AttractionLoop from "@/src/components/attraction-loop/AttractionLoop";
import HoursAndInformation from "@/components/splash-screen/sub-components/hours-and-information/HoursAndInformation";
import KioskApp from "@/src/KioskApp";
import SplashScreenBody from "@/src/components/splash-screen/SplashScreenBody";
import SupportModal from "@/gc/modals/support-modal/SupportModal";

// Context
import { useCustomerDetectionContext } from "@/context/CustomerDetectionContext";
import { useCustomerSupportContext } from "@/context/CustomerSupportContext";

import { useDeviceInformationContext } from "@/context/device-information/DeviceInformationContext";
import { useKioskUserSessionContext } from "@/context/KioskUserSessionContext";
import { useSchedulesContext } from "@/context/SchedulesContext";
import { useStoreDetailsContext } from "@/context/store-details/StoreDetailsContext";

// Hooks
import useSessionStorage from "@/hooks/useSessionStorage";

// Node Modules
import { useRouter } from "next/router";
import { v4 as uuidv4 } from "uuid";
import {
  DragEvent,
  type KeyboardEvent,
  MouseEvent,
  useEffect,
  useState,
} from "react";

// Scripts
import { kioskPageContentEntryIds } from "@/scripts/constant-types/pageContent";
import {
  accessibilitySizeOptions,
  resetZoom
} from "@/scripts/accessibilityHelper";

// Services
import dataLayerService from "@/services/dataLayerService";
import userSessionService from "@/components/services/userSessionService";

// Types
import { IAttractionLoopInfoFields } from "@/contentful-types";
import IIndexProps from "@/interfaces/pages/IIndexProps";

// Utils
import {
  getAttractionLoopVideoInformation,
  useSubPageNavigation
} from "@/scripts/kioskUtil";

const Index = ({
  content,
}: IIndexProps) => {
  const {
    currentPage,
    handleBack,
    handleForward,
    resetCurrentPage,
  } = useSubPageNavigation(2, 1);

  const
    {
      small,
    } = accessibilitySizeOptions;

  const router = useRouter();
  const queryParamDisplayAttractionLoop: boolean = router?.query.displayAttractionLoop == "true";
  const [displayNoInventoryScreen, setDisplayNoInventoryScreen] = useState(false);
  const isSplashScreen = currentPage === 1 && !displayNoInventoryScreen;
  const isHomeButtonEnabled = currentPage != 1 || displayNoInventoryScreen;
  const [showPinchScreenTutorial, setShowPinchScreenTutorial] = useSessionStorage("pinchScreenTutorial", true, false);
  const [sessionFontSize, setSessionFontSize] = useSessionStorage(
    "fontSizeZoomValue",
    1,
    false
  );

  const {
    deviceInformation,
  } = useDeviceInformationContext();

  const {
    address,
    checkForAvailableInventory,
    hasAvailableInventory,
  } = useStoreDetailsContext();

  const {
    clearCustomerDetectionEventRef,
  } = useCustomerDetectionContext();

  const {
    setContentfulValuesOnScheduleContext,
  } = useSchedulesContext();

  const {
    closeAttractionLoopRef,
    showAttractionLoop,
    sessionUserId,
    setShowAttractionLoop,
    setSessionUserId,
  } = useKioskUserSessionContext();

  const {
    isSupportModalFeatureEnabled,
    setHasAttractionLoopJustBeenClosed,
  } = useCustomerSupportContext();

  const [attractionLoopVideoInformation, setAttractionLoopVideoInformation] = useState<IAttractionLoopInfoFields>();

  useEffect(() => {
    const bestAttractionLoopVideoInformation = getAttractionLoopVideoInformation(content, deviceInformation);
    setAttractionLoopVideoInformation(bestAttractionLoopVideoInformation)

    setContentfulValuesOnScheduleContext(content.defaultStoreSchedule, content?.hoursAndInformation.fields?.twentyFourHoursHeading!);

    if (queryParamDisplayAttractionLoop) {
      setShowAttractionLoop(queryParamDisplayAttractionLoop);
    }
  }, []);

  const getHandleBackFunctionality = () => {
    if (isSplashScreen) {
      handleForward();
    } else if (displayNoInventoryScreen) {
      setDisplayNoInventoryScreen(false);
    } else {
      handleBack();
    }
  };

  const showSplashScreen = () => {
    resetCurrentPage();
    setDisplayNoInventoryScreen(false);
  };

  useEffect(() => {
    if (showAttractionLoop) {
      resetCurrentPage();
      endUserSession();
    }
  }, [showAttractionLoop]);

  useEffect(() => {
    setCustomDimensionsForAnalytics();
  }, [deviceInformation]);

  const closeAttractionLoop = async (event?: DragEvent<HTMLDivElement> | KeyboardEvent<HTMLDivElement> | MouseEvent<HTMLDivElement>): Promise<void> => {
    if (!attractionLoopVideoInformation?.isClosable || (event instanceof KeyboardEvent && event?.key == "Shift")) {
      return;
    }

    if (router?.query.displayAttractionLoop) {
      router.replace("/", undefined, {
        shallow: true,
      });
    }

    setShowAttractionLoop(false);
    setHasAttractionLoopJustBeenClosed(true);
    await checkForAvailableInventory();
    startUserSession();
  };

  const startUserSession = () => {
    const userId: string = uuidv4();
    setSessionUserId(userId);
    userSessionService.startSession(deviceInformation.ozStoreId!, userId);
    setShowPinchScreenTutorial(true);
    resetFontSize();
  };

  const resetFontSize = () => {
    const findBodyElement = setTimeout(() => {
      const childrenBodyElement: HTMLElement = document.getElementById("children-body") as HTMLElement;
      if (childrenBodyElement) {
        resetZoom(document);
        setSessionFontSize(small.zoom);
        clearInterval(findBodyElement);
      }
    }, 1)
  };

  const endUserSession = (): void => {
    userSessionService.endSession(deviceInformation.ozStoreId!, sessionUserId);
    setSessionUserId("");
    !!clearCustomerDetectionEventRef.current && clearCustomerDetectionEventRef.current();
  };

  const setCustomDimensionsForAnalytics = () => {
    const interval = setInterval(() => {
      const ozStoreId: string | undefined = deviceInformation?.ozStoreId;
      if (ozStoreId) {
        dataLayerService.setCustomDimensions(ozStoreId);
        clearInterval(interval);
      }
    }, 1000);
  };

  useEffect(() => {
    closeAttractionLoopRef.current = closeAttractionLoop;
  }, [closeAttractionLoop]);

  return (
    showAttractionLoop && attractionLoopVideoInformation
      ? (
        <AttractionLoop
          closeAttractionLoop={closeAttractionLoop}
          videoSrc={attractionLoopVideoInformation?.video.fields.file.url}
        />
      )
      : (
        <KioskApp
          content={content}
          disableBackButton={isSplashScreen}
          handleBack={() => getHandleBackFunctionality()}
          headTitle="Splash Screen"
          homeButtonOnClick={showSplashScreen}
          isHomeButtonEnabled={isHomeButtonEnabled}
          passiveTimerFunctionality={currentPage === 1
            ? (() => setShowAttractionLoop(true))
            : undefined}
          shouldShowDiagnosticsModal={currentPage === 2}
        >
          {isSupportModalFeatureEnabled && (
            <SupportModal
              content={content}
            />
          )}

          {currentPage === 1 && (
            <SplashScreenBody
              address={address}
              content={content}
              displayNoInventoryScreen={displayNoInventoryScreen}
              handleForward={handleForward}
              hasAvailableInventory={hasAvailableInventory}
              setDisplayNoInventoryScreen={setDisplayNoInventoryScreen}
            />
          )}
          {currentPage === 2 && (
            <HoursAndInformation
              content={content}
            />
          )}
        </KioskApp>
      )
  );
};

Index.pageContentEntryId = kioskPageContentEntryIds.splashScreen;

export default Index;
