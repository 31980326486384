// Node Modules
import css from "styled-jsx/css";

// Themes
import theme from "@/theme";

export default function getSupportModalStyles() {
  return css.resolve`
      .modal-content{
        position: fixed;
        height: 83.3%;
        width: 100%;
        top: 9.6%;
        background: white;
        z-index: 5;
        
      }

      .main-content{
        width: 100%;
        height: 100%;
      }

      .icon-section{
        width: 100%;
        text-align: center;
        padding: 15rem 0rem 8rem 0rem;
      }

      .icon{
        font-size: 11rem;
        color: ${theme.brandColors.primaryGreen};
      }

      .text-info-section{
        width: 100%
      }

      h3{
        color: ${theme.fontColors.black};
        text-align: center;
        width: 100%;
        padding: 0px 13%;
        font-size: 8rem;
      }

      p{
        text-align: center;
        padding: 7rem 13%;
        width: 100%;
      }

      .button-section{
        width: 100%;
        padding: 5rem 11%;
      }

      .support-modal-button{
        width: 100%;
        display: block;
        margin: 3rem 0px;
        background: white;
        border: solid 3px ${theme.brandColors.primaryGreen};
        border-radius: 52px;
        padding: 3.7rem 1rem;
        font-size: 3.2rem;
        text-transform: uppercase;
        font-weight: 700;
      }
  
    `;
}
