// Components
import FontAwesomeIcon from "@/gc/icon/FontAwesomeIcon";
import IPageTitleProps from "@/interfaces/global-components/page-title/IPageTitleProps";

// Styles
import { StylesPageTitle } from "./StylesPageTitle";

const PageTitle = ({
  containerPadding,
  fontWeight,
  icon,
  iconColor,
  iconSize,
  subTitle,
  subTitleTestId = "sub-title",
  title,
  titleTestId,
  width,
}: IPageTitleProps) => {
  const {
    className,
    styles,
  } = StylesPageTitle(containerPadding, width);

  return (
    <>
      {icon && (
        <div
          className={`icon-container ${className}`}
          data-testid="icon"
        >
          <span>
            <FontAwesomeIcon
              color={iconColor}
              icon={icon}
              size={iconSize}
            />
          </span>
        </div>
      )}
      <div
        className={`page-title-container ${className}`}
      >
        <h1
          className={`page-title ${className}`}
          data-testid={titleTestId ?? "title"}
        >{title}</h1>
        <p
          className={fontWeight
            ? `bold page-text ${className}`
            : `page-text ${className}`}
          data-testid={subTitleTestId ?? "sub-title"}
        >{subTitle}</p>
      </div>
      {styles}

    </>
  );
};

export default PageTitle;

