// Components
import ContactHours from "@/components/global-components/contact-hours/ContactHours";
import HoursOfOperation from "@/components/global-components/store-hours/HoursOfOperation";
import RichText from "@/gc/rich-text/RichText";
import TitleHeader from "@/components/global-components/title/TitleHeader";

// Context
import {
  featureNames,
  useFeatureToggleContext
} from "@/context/FeaturesToggleContext";

// Icons
import {
  faGarage,
  faPhoneAlt,
  faStore,
  faTasks
} from "@fortawesome/pro-regular-svg-icons";

// Services
import * as localStorageService from "@/services/localStorageService";
import { getCustomerServiceContactInformationItem } from "@/services/contactInformationService";

// Styles
import { StylesHoursAndInformation } from "./StylesHoursAndInformation";

// Types
import { IHoursAndInformationFieldsModified } from "@/interfaces/hours-and-information/IHoursAndInformationFieldsModified";
import { IStoreSchedule } from "@/interfaces/hours-of-operation/IHoursOfOperation";
import { useDeviceInformationContext } from "@/context/device-information/DeviceInformationContext";

const HoursAndInformation = ({
  content,
}: IHoursAndInformationFieldsModified) => {
  const {
    deviceInformation,
  } = useDeviceInformationContext();

  const {
    className,
    styles,
  } = StylesHoursAndInformation();

  const {
    featureToggleList,
  } = useFeatureToggleContext();

  const {
    contactInformationList,
  } = content?.hoursAndInformation?.fields;

  const featureToggle = featureToggleList.find(item => item.featureName == featureNames.flex);
  const useTwilioFlex: boolean = !!featureToggle && featureToggle.isEnabled;
  const contactInformationItem = getCustomerServiceContactInformationItem(contactInformationList, deviceInformation, useTwilioFlex);
  const hoursInfoState = content?.hoursAndInformation.fields;
  const features = hoursInfoState?.features;
  const hoursInformation: IStoreSchedule = localStorageService.getHoursOfOperation();

  const {
    accessHoursTooltipHtml,
    officeHoursTooltipHtml,
    phoneHoursTooltipHtml,
  } = content.hoursAndInformation.fields;

  const toolTipElements = [accessHoursTooltipHtml];

  const contactHoursElements = [officeHoursTooltipHtml, phoneHoursTooltipHtml];

  const iconProps = {
    phone: faPhoneAlt,
    store: faStore,
  };

  return (
    <>
      <div>
        <div className={`access-hours-container ${className}`}>
          <TitleHeader
            defaultIconSize="3rem"
            icon={faGarage}
            maxWidth="57rem"
            testId="access-hours"
            title={hoursInfoState?.accessHoursHeading}
            toolTipContent={<RichText document={toolTipElements[0]} />}
          />
        </div>
        <div className={`content-container ${className}`}>
          <HoursOfOperation
            upperCase
            hoursOfOperation={hoursInformation?.gate}
          />
        </div>
        <ContactHours
          contactInformationItem={contactInformationItem}
          hoursInfoState={hoursInfoState}
          hoursInformation={hoursInformation}
          icons={iconProps}
          marginAdjust="-13"
          toolTipContent={contactHoursElements}
        />
        <div className={`divider-line ${className}`} />
        <div className={`access-hours-container ${className}`}>
          <TitleHeader
            defaultIconSize="3rem"
            icon={faTasks}
            maxWidth="57rem"
            testId="features-list-header"
            title={hoursInfoState?.featuresHeading}
          />
        </div>
        <div className={`features-container ${className}`}>
          <div className={` ${className}`}>
            <ul
              className={`feature-ul ${className}`}
              data-testid="feature-list"
            >
              {features?.map(feature => (
                <li
                  className={`feature-item ${className}`}
                  key={feature}
                >{[feature]}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <style>
        {styles}
      </style>
    </>
  )
};

export default HoursAndInformation;
